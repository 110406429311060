import { isMobile } from './is-mobile'

/*
 * Fixed position header
 * ----------------------------------------------- */
jQuery(window).on('load scroll', function () {
  var $jsHeader = $('.js-header')
  var offset = isMobile() ? 50 : 100

  $jsHeader.each(function () {
    var scroll = $(window).scrollTop()

    if (scroll > offset) {
      $jsHeader.addClass('is-scrolled')
    } else {
      $jsHeader.removeClass('is-scrolled')
    }
  })
})
